// eslint-disable-next-line import/no-extraneous-dependencies
import { defaultTokens, getTokens } from '@kiwicom/orbit-design-tokens';
import qs from 'query-string';

import languages from '../data/languages.json';

export const globalTokens = {
  base: {
    fontFamily:
      '"Circular Pro", -apple-system, ".SFNSText-Regular", "San Francisco", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;',
  },
};

const customTokens = getTokens(globalTokens);

const { lang } = qs.parse(window.location.search);

const theme = {
  rtl: languages[lang || 'en']?.direction === 'rtl' || false,
  orbit: {
    ...defaultTokens,
    ...customTokens,
  },
};

theme.orbit.backgroundButtonPrimary = "#021aee";
//theme.orbit.colorTextPrimary = "rgb(0,255,21)";
theme.orbit.backgroundBody = "#BBDEFB";
//theme.orbit.primaryColorDepr = "#ff00fe30";
//theme.orbit.backgroundButtonPrimaryActive = "rgb(28,40,26)";
//theme.orbit.backgroundButtonBundleBasic = "rgba(127,180,116,0.45)";
export default theme;
