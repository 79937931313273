import React, { useCallback, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Tag from '@kiwicom/orbit-components/lib/Tag';
import InputField from '@kiwicom/orbit-components/lib/InputField';

import { getShortName } from '../../utils/place';
import debounce from '../../utils/debounce';

import LocationPickerPopup from './components/Popup';
import Query from './components/Query';
import  PlacesProvider from './services/PlacesProvider';
import  usePlacePickerState  from './services/usePlacePickerState';

import  useTranslations  from '../../services/translations/useTranslations';

const PositionWrapper = styled.div`
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  height: ${({ theme }) => parseInt(theme.orbit.heightInputNormal, 10)}px;

  @media (min-width: 576px) {
    width: calc(50% - 4px);
  }

  @media (min-width: 1200px) {
    width: calc(25% - 5px);
  }
`;

export const getTagStyles = ({ direction }) => {
  switch (direction) {
    case 'source':
      return css`
        background-color: ${({ theme }) => theme.orbit.backgroundButtonPrimary};

        &:hover {
          background-color: ${({ theme }) => theme.orbit.backgroundButtonPrimaryHover};
        }
      `;
    case 'destination':
      return css`
        background-color: rgb(249, 151, 30);

        &:hover {
          background-color: rgb(219, 123, 6);
        }
      `;
    default:
      return null;
  }
};

const TagWrapper = styled.div`
  & > div {
    color: rgb(255, 255, 255);
    transition: background-color 200ms ease-in-out;

    ${getTagStyles};

    svg {
      fill: white;
      opacity: 0.6;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }
  }
`;

const PlacePickerComponent = ({ prefix, direction, values }) => {

  const { translate } = useTranslations();
  const { active, input, setInput, setActive, removePlace, placePickerRef, addPlaces } =
    usePlacePickerState();

  const [selectedPlaces, setSelectedPlaces] = useState([]);
  useEffect(() => {
    setSelectedPlaces(values);
  }, [values]);

  const handleAddPlace = place => {
    setSelectedPlaces([...selectedPlaces, place]);
  };

  const handleRemovePlace = place => {
    setSelectedPlaces(selectedPlaces.filter(p => p.name !== place.name));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce(value => {
      setInput(value);
      setActive(true);
    }, 333),
    [],
  );

  const handleOpen = useCallback(() => {
    setActive(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = useCallback(() => {
    setActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    handleClose();
    if (JSON.stringify(values) !== JSON.stringify(selectedPlaces)) {
      addPlaces(selectedPlaces);
    }
  };

  return (
    <PositionWrapper data-test={`SearchPlaceField-${direction}`}>
      <LocationPickerPopup active={active} handleOutside={handleSubmit}>
        <InputField
          dataTest="SearchField-input"
          inlineLabel
          autoComplete="off"
          label={prefix}
          onFocus={handleOpen}
          onChange={e => {
            handleChange(e.target.value);
          }}
          error={null}
          ref={placePickerRef}
          tags={
            selectedPlaces.length > 0 && (
              <TagWrapper direction={direction}>
                {selectedPlaces.map(place => (
                  <Tag
                    dataTest="PlacePickerInputPlace"
                    key={place.slug}
                    onRemove={active ? () => handleRemovePlace(place) : () => removePlace(place)}
                    selected
                  >
                    {
                      translate(getShortName(place))
                    }
                  </Tag>
                ))}
              </TagWrapper>
            )
          }
        />

        <Query
          active={active}
          searchTerm={input}
          direction={direction}
          values={selectedPlaces}
          onClose={handleClose}
          handleAddPlace={handleAddPlace}
          handleRemovePlace={handleRemovePlace}
          handleSubmit={handleSubmit}
        />
      </LocationPickerPopup>
    </PositionWrapper>
  );
};

const PlacePicker = ({ onChange, max, direction, values, ...props }) => {
  return (
    <PlacesProvider onChange={onChange} max={max} values={values} direction={direction}>
      <PlacePickerComponent {...props} direction={direction} values={values} />
    </PlacesProvider>
  );
};

export default PlacePicker;
