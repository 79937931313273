import React, { useState } from 'react';
import Measure from 'react-measure';
import SearchResults from '../../../../scenes/Basic/scenes/SearchResults';
import useEntryParameters from '../../../../services/entryParameters/useEntryParameters';
import  useSearch from '../../../../scenes/Basic/services/SearchActions/useSearch';
import  useAggregatedSearch from '../../../../scenes/Basic/services/SearchActions/useAggregatedSearch';
import  useGeoData from '../../../../services/geo/useGeoData';
import { usePostMessages } from '../../../../services/postMessages';


import SearchForm from './components/SearchForm';
import Filters from './components/Filters';
import {
  Container,
  FormAndFiltersContainer,
  ResultsContainer,
  LoaderBar,
  LoaderWrapper,
  LinksWrapper,
  Footer,
} from './index.styled';
import ButtonLink from "@kiwicom/orbit-components/lib/ButtonLink";
//import Footer from "@kiwicom/nitro/lib/components/Footer/index";


const BasicSearchForm = () => {
  const { closestCities } = useGeoData();
  const entryParameters = useEntryParameters();
  const [height, setHeight] = useState(1);
  const { postIframeHeight } = usePostMessages();

  //increase by deeplink overhead if widget is embedded into deeplink preview
  const increaseBy = entryParameters.iframeId === 'deeplink-generator' ? 400 : 0;

  const search = useSearch();
  const aggregatedSearch = useAggregatedSearch();

  const handleResize = contentRect => {
    if (height !== contentRect.client.height) {
      setHeight(contentRect.client.height);
      postIframeHeight(contentRect.client.height + increaseBy);
    }
  };

  if (!closestCities.data) {
    return null;
  }

  const showSearchForm = !entryParameters.resultsOnly;


  return (
    <Measure client onResize={handleResize}>

      {({ measureRef }) => (
        <Container ref={measureRef}>
          {showSearchForm && (
            <FormAndFiltersContainer style={{'background':'rgb(255 0 254)'}}>
              <Filters />
              <SearchForm />
            </FormAndFiltersContainer>
          )}
          {(search.results.loading ||
            aggregatedSearch.results.loading
             || search.results.loadingMore) && (
            <LoaderWrapper>
              <LoaderBar data-test="LoaderBar" />
            </LoaderWrapper>
          )}
          <ResultsContainer>
            <SearchResults />
          </ResultsContainer>
          <Footer  >
            <LinksWrapper>
              <ButtonLink type="secondary" size="small" href={"https://airlinestravel.ge/"}><h1>airlinestravel.ge</h1></ButtonLink>
              <ButtonLink type="secondary" size="small" href={"/ავიაბილეთები"}><h1>ავიაბილეთები </h1></ButtonLink>
              <ButtonLink type="secondary" size="small" href={"https://airlinestravel.ge/ავიზები"}><h1>avia tikets </h1></ButtonLink>
            </LinksWrapper>
            <div style={{textAlign:"center"}}><ButtonLink size="small" type="secondary"  href={"https://oop.ge/"}>© 2023 oop.ge</ButtonLink></div>
          </Footer>
        </Container>
      )}

    </Measure>
  );
};

export default BasicSearchForm;
