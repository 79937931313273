import styled, { css } from 'styled-components';
import media from '@kiwicom/orbit-components/lib/utils/mediaQuery';

const widthStyles = css`
  max-width: 1400px;
  width: 100%;
  min-width: 250px;
  margin: 0 auto;
`;

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5px 16px 0;
  ${widthStyles}

  ${media.desktop(css`
    padding: 5px 40px 0;
  `)}
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) =>
    theme.deprecatedColors.searchFormSelectedTextColor &&
    theme.deprecatedColors.searchFormSecondaryTextColor &&
    css`
      & > div > button {
        color: ${({ theme }) => theme.deprecatedColors.searchFormSelectedTextColor} !important;

        & > div > div > svg {
          color: ${({ theme }) => theme.deprecatedColors.searchFormSelectedTextColor} !important;
        }

        &:hover {
          background-color: ${({ theme }) => theme.deprecatedColors.searchFormSecondaryTextColor};
        }
      }
    `}
`;
