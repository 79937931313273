import { useLayoutEffect, useState, useEffect, useRef, useMemo } from 'react';

import debounce from '../utils/debounce';
import throttle from '../utils/throttle';

export const useBodyScrollLock = () => {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, []);
};

export const useUpdateReason = (name, props) => {
  const previousProps = useRef();

  useEffect(() => {
    if (previousProps.current) {
      const keysTable = {};

      // eslint-disable-next-line guard-for-in
      for (const key in previousProps.current) {
        keysTable[key] = true;
      }
      // eslint-disable-next-line guard-for-in
      for (const key in props) {
        keysTable[key] = true;
      }

      const allKeys = Object.keys(keysTable);
      const changesTable = {};

      for (const key of allKeys) {
        if (previousProps.current[key] !== props[key]) {
          changesTable[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      }

      if (Object.keys(changesTable).length) {
        // eslint-disable-next-line no-console

      }
    }

    previousProps.current = props;
  });
};

export const useConstant = fn => {
  const ref = useRef();

  if (!ref.current) {
    ref.current = { v: fn() };
  }

  return ref.current.v;
};

export const useToggle = (initialState = false) => {
  const [isOn, setIsOn] = useState(initialState);

  return useMemo(() => {
    return {
      isOn,
      set: a => setIsOn(a),
      toggle: () => setIsOn(a => !a),
      setOn: () => setIsOn(true),
      setOff: () => setIsOn(false),
    };
  }, [isOn]);
};

// https://medium.com/swlh/prevent-useeffects-callback-firing-during-initial-render-the-armchair-critic-f71bc0e03536
export const useDependencyEffect = (effect, dependencies) => {
  const initialRender = useRef(true);

  useEffect(() => {
    let effectReturns = () => {};
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      effectReturns = effect();
    }

    if (effectReturns && typeof effectReturns === 'function') {
      return effectReturns;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

const events = new Set();
const onResize = () => events.forEach(f => f());

/**
 * @param {'throttle' | 'debounce'} restrictFunction
 *
 */
export const useWindowSize = (restrictFunction = 'throttle') => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const restrictor = restrictFunction === 'throttle' ? throttle : debounce;

  const handle = restrictor(() => {
    setSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, 300);

  useEffect(() => {
    if (events.size === 0) {
      window.addEventListener('resize', onResize, true);
    }

    events.add(handle);

    return () => {
      events.delete(handle);

      if (events.size === 0) {
        window.removeEventListener('resize', onResize, true);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return size;
};
