import React from 'react';
import styled from 'styled-components';
import Button from '@kiwicom/orbit-components/lib/Button';
import Text from '@kiwicom/orbit-components/lib/Text';
import Heading from '@kiwicom/orbit-components/lib/Heading';
import Illustration from '@kiwicom/orbit-components/lib/Illustration';
import Stack from '@kiwicom/orbit-components/lib/Stack';

import  useTranslations  from '../../services/translations/useTranslations';

const Wrapper = styled.div`
  width: 100%;
  margin-top: 10%;
  & > button {
    margin: 0 auto;
  }
`;

const NoResults = ({ handleReset, isSingleWidget, invalidAffilid }) => {
  const { translate } = useTranslations();

  return (
    <Wrapper>
      <Stack direction="column" justify="start" align="center">
          <Illustration name="NoBookings"  size={isSingleWidget && 'small'} />
        <Heading type="title2" spaceAfter="small">
          {translate(('result.no_results_title'))}
        </Heading>
        <Text align="middle" type="secondary" spaceAfter="large">
          {translate(('result.no_results_desc'))}
        </Text>
        {handleReset && <Button onClick={handleReset}>{translate(('common.back'))}</Button>}
        {invalidAffilid && (
          <Text align="middle" type="secondary" size="small" spaceAfter="large">
            Provided affiliate id is invalid.
          </Text>
        )}
      </Stack>
    </Wrapper>
  );
};

export default NoResults;
