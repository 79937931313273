/* global __ */
import React, { useEffect } from 'react';

import  useTranslations  from '../../../../../../services/translations/useTranslations';
import  useSearchFormState  from '../../../../../../scenes/Basic/services/SearchForm/useSearchForm';
import  useSearch  from '../../../../../../scenes/Basic/services/SearchActions/useSearch';
import  useAggregatedSearch  from '../../../../../../scenes/Basic/services/SearchActions/useAggregatedSearch';
import PlacePicker from '../../../../../../common/PlacePicker';
import DatePicker from '../../../../../../common/Datepicker';
import SearchButton from '../../../../../../common/SearchButton';
import { useGTM } from '../../../../../../services/tracking/useGTM';
import { GTM_EVENTS } from '../../../../../../consts/tracking';
import { AMBIGUOUS_PLACE_TYPES } from '../../../../../../consts/place';

import { FormContainer, FormWrapper } from './index.styled';

const SearchForm = () => {
  const { translate } = useTranslations();
  const search = useSearch();
  const aggregatedSearch = useAggregatedSearch();
  const {
    source,
    destination,
    outboundDate,
    inboundDate,
    onSourceChange,
    onDestinationChange,
    onOutboundDateChange,
    onInboundDateChange,
    mode,
  } = useSearchFormState();
  const { pushToDataLayer } = useGTM();

  useEffect(() => {
    pushToDataLayer(GTM_EVENTS.INITIAL_LOAD);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchClick = () => {
    pushToDataLayer(GTM_EVENTS.SEARCH_BUTTON);
    if (destination.length > 0 && destination.every(p => !AMBIGUOUS_PLACE_TYPES[p.type])) {
      search.loadResults();
    } else {
      aggregatedSearch.loadResults();
    }
  };

  return (
    <FormContainer >
      <FormWrapper  >
        <PlacePicker
          prefix={translate(('search.form.from_nocolon'))}
          direction="source"
          values={source}
          onChange={onSourceChange}
        />
        <PlacePicker
          prefix={translate(('search.form.to_nocolon'))}
          direction="destination"
          values={destination}
          onChange={onDestinationChange}
        />
        <DatePicker
          prefix={translate(('search.form.date_nocolon'))}
          suffix={outboundDate !== 'anytime'}
          direction="outbound"
          values={outboundDate}
          mode={mode}
          onChange={onOutboundDateChange}
        />
        <DatePicker
          prefix={mode !== 'one-way' && translate(('search.form.return_nocolon'))}
          suffix={inboundDate !== 'anytime'}
          direction="inbound"
          mode={mode}
          disabled={mode === 'one-way'}
          values={inboundDate}
          onChange={onInboundDateChange}
        />
      </FormWrapper>

      <SearchButton onClick={handleSearchClick} />
    </FormContainer>
  );
};

export default SearchForm;
