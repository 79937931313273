import React from 'react';

import  DirectionFilter from '../../../../../../common/Filters/Direction';
import  ClassFilter from '../../../../../../common/Filters/Class';
import  PassengersAndBags from '../../../../../../common/Filters/PassengersAndBags';
import  LanguagesFilter from '../../../../../../common/Filters/Languages';
import  CurrenciesFilter from '../../../../../../common/Filters/Currencies';
import useViewport from '../../../../services/Viewport/useViewport';
import Dialog from "@kiwicom/orbit-components/lib/Dialog";
import { FiltersContainer, FiltersWrapper } from './index.styled';
import useSearchFormState from "src/scenes/Basic/services/SearchForm/useSearchForm";
import ButtonLink from "@kiwicom/orbit-components/lib/ButtonLink";
import translate from "src/common/Translate";
import Translate from "src/common/Translate";




const Filters = () => {
 // const { translate ,language} = TranslationProvider();
  const { mode, setSearchMode } = useSearchFormState();
  const { isMobile } = useViewport();
  const [about, setAbout] = React.useState(false);
  const [con, setCon] = React.useState(false);


  const popoverActions = isMobile ? <></> : undefined;

  return (
      <div style={{"background": "#fff","marginBottom":"15px"}}>{
          about ?      (<Dialog
              maxWidth={600}
              title={<>
                <div style={{textAlign:"center"}}>
                <div> <img style={{"width": "100px"}} src={'logo192.png'} /></div>
                  <div> Airlines Travel</div>
              </div></>}
              description={
                <div style={{"text-align": "justify"}}>
                  <Translate tkey="About.item" html="true" />
                </div>
              }
              primaryAction={<ButtonLink type="critical" onClick={()=>{setAbout(false);}}>დახურვა</ButtonLink>}
          />):(undefined)
      }
      {
          con ?      (<Dialog
              title={  <>
                <div>
                  EMAIL:
                  <ButtonLink
                      type="secondary"
                      href={'mailto:airlinestravel7@gmail.com'}
                      size="small">airlinestravel7@gmail.com
                  </ButtonLink>
                </div>
                <div>
                  NUMBER
                  <ButtonLink
                  href={'tel:+995 574 229020'}
                  type="secondary"
                  size="small">+995 574 229020
                  </ButtonLink>
                </div>
                </>
              }

              primaryAction={<ButtonLink type="critical" onClick={()=>{setCon(false);}}>დახურვა</ButtonLink>}
          />):(undefined)
          }

    <FiltersContainer  >

      <FiltersWrapper>
        <a href="" style={{"background": "#ff00fe",  "padding":" 0 10px", "border-radius": "10px 10px 0 0"}}><img className={'logo'} src="/static/logo3.png" /></a>
      </FiltersWrapper>
        {
            isMobile ?(    <FiltersWrapper >
                <ButtonLink type="secondary" size="small" onClick={()=>{setAbout(true);}}>{translate({tkey:"About"})}</ButtonLink>
                <ButtonLink type="secondary" size="small" onClick={()=>{setCon(true);}}>კონტაქტი</ButtonLink>
              <div style={{"width": "82px","padding-top":"10px"}}><LanguagesFilter popoverActions={popoverActions} /></div>

            </FiltersWrapper>):undefined
        }

        <FiltersWrapper>
            <DirectionFilter value={mode} onChange={setSearchMode} popoverActions={popoverActions} />
            <ClassFilter popoverActions={popoverActions} />
            <PassengersAndBags popoverActions={popoverActions} />
      </FiltersWrapper>

      <FiltersWrapper>

        <CurrenciesFilter popoverActions={popoverActions} />

      </FiltersWrapper>
      <div style={{"flex":"1"}}></div>
        {
            !isMobile ?(    <FiltersWrapper >
            <ButtonLink type="secondary" size="small" onClick={()=>{setAbout(true);}}>{translate({tkey:"About"})}</ButtonLink>
            <ButtonLink type="secondary" size="small" onClick={()=>{setCon(true);}}>კონტაქტი</ButtonLink>

            <div style={{"width": "82px","padding-top":"10px"}}><LanguagesFilter popoverActions={popoverActions} /></div>
            </FiltersWrapper>):undefined
        }




    </FiltersContainer></div>
  );
};

export default Filters;
