import React from 'react';
import { ThemeProvider } from 'styled-components';
import { getTokens } from '@kiwicom/orbit-design-tokens';
import  PassengersAndBagsProvider  from '../../services/filters/PassengersAndBags/PassengersAndBagsProvider';
import  SearchProvider  from '../../services/filters/Search/SearchProvider';
import  useTranslations  from '../../services/translations/useTranslations';
import useEntryParameters from '../../services/entryParameters/useEntryParameters';
import BasicSearchForm from '../../scenes/Basic/scenes/BasicSearchForm';
import { globalTokens } from '../../styles/theme';
import SearchAggregatedQueryProvider from "src/scenes/Basic/services/SearchActions/SearchAggregatedQueryProvider";
import SearchFormProvider from "src/scenes/Basic/services/SearchForm/SearchFormProvider";
import SearchQueryProvider from "src/scenes/Basic/services/SearchActions/SearchQueryProvider";


const Basic = () => {
  const { translations } = useTranslations();
  const entryParams = useEntryParameters();

  if (!translations) {
    return 'Loading';
  }

  const updateTheme = theme => {
    if (!entryParams.deprecatedColors) {
      return { ...theme, deprecatedColors: {} };
    }
    const updatedTheme = {
      ...theme,
      ...{ orbit: getTokens({ ...globalTokens, ...entryParams.deprecatedColors.themeOverrides }) },
      deprecatedColors: entryParams.deprecatedColors.customTokens,
    };
    return updatedTheme;
  };

  return (
    <ThemeProvider theme={updateTheme}>
      <SearchFormProvider>
        <PassengersAndBagsProvider noConfig>
          <SearchProvider>
            <SearchAggregatedQueryProvider>
              <SearchQueryProvider>
                <BasicSearchForm />
              </SearchQueryProvider>
            </SearchAggregatedQueryProvider>
          </SearchProvider>
        </PassengersAndBagsProvider>
      </SearchFormProvider>
    </ThemeProvider>
  );
};

export default Basic;
