/* global __ */
import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import Popover from '@kiwicom/orbit-components/lib/Popover';
import ButtonLink from '@kiwicom/orbit-components/lib/ButtonLink';
import Button from '@kiwicom/orbit-components/lib/Button';
import ListChoice from '@kiwicom/orbit-components/lib/ListChoice';
import CheckIcon from '@kiwicom/orbit-components/lib/icons/Check';
import ChevronDownIcon from '@kiwicom/orbit-components/lib/icons/ChevronDown';
import languages from '../../data/languages.json';
import  useTranslations  from '../../services/translations/useTranslations';
import useViewport from '../../scenes/Basic/services/Viewport/useViewport';
import { usePostMessages } from '../../services/postMessages';
import { useGTM } from '../../services/tracking/useGTM';
import { GTM_EVENTS } from '../../consts/tracking';
import PopoverActions from '../../common/PopoverActions';
import {ButtonGroup, LinkList} from "@kiwicom/orbit-components";
import CountryFlag from "@kiwicom/orbit-components/lib/CountryFlag";
import TextLink from "@kiwicom/orbit-components/lib/TextLink";
const EmptyIcon = styled.div`
  width: 16px;
  height: 16px;
`;

const DirectionWrapper = styled.div`
  padding: 16px 16px 0 16px;
`;

const options = Object.values(languages).map(e=>{return {label:e.displayName,flag:e.flag,value:e.id}});

/*[
    {
        label: ('search.form.journey_mode.return'),
        value: 'return',
    },
    {
        label: ('search.form.journey_mode.one_way'),
        value: 'one-way',
    },
];
*/
const LanguagesFilter = ({ value = 'return', onChange, popoverActions }) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    let { translate,lang, setLanguage } = useTranslations();
    const { isMobile } = useViewport();
    const { postIframeHeight, lockResizing, unlockResizing } = usePostMessages();
    const [contentHeight, setContentHeight] = useState(false);
    const { pushToDataLayer } = useGTM();
    const [direction, setDirection,] = useState(value);


    return (
        <LinkList noPadding direction="row"         spacing="XXSmall">


            {options.map(o => (
                <div    className={lang==o.value?"":'bw'}>
                <TextLink
                    size="extraLarge"

                    key={o.value}
                    onClick={() => {
                        setLanguage(o.value);
                    }}
                    iconLeft={<CountryFlag code={o.flag} />}
                >
                </TextLink>
                </div>
            ))}
        </LinkList>
    )/*
    return (
        <Popover
            opened={popoverOpen}
            onOpen={handleOpenPopover}
            onClose={handleClosePopover}
            overlapped
            noPadding
            fixed
            actions={popoverActions}
            content={
                <DirectionWrapper>

                    <PopoverActions onClose={handleClosePopover} onSubmit={handleSubmit} spaceAfter="none" />

                    {options.map(o => (
                        <Button
                            dataTest={`ModePopupOption-${o.value}`}
                            width="100%"
                            type={lang==o.value?"primary":'secondary'}
                            key={o.value}
                            onClick={() => {
                               setLanguage(o.value);
                            }}
                        >
                            {o.label}
                        </Button>
                    ))}
                </DirectionWrapper>
            }
        >
            <ButtonLink
                dataTest="SearchFormModesPicker"
                size="small"
                type="secondary"
                iconRight={<ChevronDownIcon />}
            >
                {translate(options.find(o => o.value === lang).label)}
            </ButtonLink>
        </Popover>
    );*/
};

export default LanguagesFilter;
