/* global __ */
import React, { useContext, Fragment, useCallback, useLayoutEffect } from 'react';
import Mobile from '@kiwicom/orbit-components/lib/Mobile';
import Desktop from '@kiwicom/orbit-components/lib/Desktop';
import TextLink from '@kiwicom/orbit-components/lib/TextLink';
import Loading from '@kiwicom/orbit-components/lib/Loading';
import HorizontalScroll from '@kiwicom/orbit-components/lib/HorizontalScroll';
import ChevronLeftIcon from '@kiwicom/orbit-components/lib/icons/ChevronForward';

import { range } from '../../../../../../utils/array';
import ResultCardPlaceholder from '../../../../../../common/ResultCardPlaceholder';
import ResultCard from '../../../../../../common/ResultCard';
import { SearchState } from '../../../../../../services/filters/Search/SearchProvider';
import { mapItinerary } from '../../../../../../mappers/itinerary';
import SortBy from '../../../../../../scenes/Basic/scenes/SearchResults/components/SortBy';
import TransportFilter from '../../../../../../scenes/Basic/scenes/SearchResults/components/TransportFilter';
import StopsFilter from '../../../../../../scenes/Basic/scenes/SearchResults/components/StopsFilter';
import PriceFilter from '../../../../../../scenes/Basic/scenes/SearchResults/components/PriceFilter';
import DaysFilter from '../../../../../../scenes/Basic/scenes/SearchResults/components/DaysFilter';
import TimesFilter from '../../../../../../scenes/Basic/scenes/SearchResults/components/TimesFilter';
import MoreFilter from '../../../../../../scenes/Basic/scenes/SearchResults/components/MoreFilter';
import  useSearch  from '../../../../../../scenes/Basic/services/SearchActions/useSearch';
import useEntryParameters from '../../../../../../services/entryParameters/useEntryParameters';
import  useTranslations  from '../../../../../../services/translations/useTranslations';
import NoResults from '../../../../../../common/NoResults';
import useViewport from '../../../../../../scenes/Basic/services/Viewport/useViewport';
import  useSearchFormState  from '../../../../../../scenes/Basic/services/SearchForm/SearchFormProvider';

import {
  ResultsContainer,
  FiltersWrapper,
  ResultsWrapper,
  SortWrapper,
  Results,
  ScrollWrapper,
  TextLinkWrapper,
} from './index.styled';

const MobileFilterWrapper = props => {
  return (
    <ScrollWrapper>
      <HorizontalScroll showArrows>{props.children}</HorizontalScroll>
    </ScrollWrapper>
  );
};

const SearchResults = () => {
  const { results, loadResults } = useSearch();
  const { translate } = useTranslations();
  const { sortBy, changeSortBy } = useContext(SearchState);
  const { resetLocations } = useSearchFormState(SearchState);
  const entryParameters = useEntryParameters();
  const { isMobile, isMobileOrLargeMobile, isNotDesktop } = useViewport();

  const showFilters = !entryParameters.resultsOnly;
  const showFiltersWithResults = Boolean(showFilters && results?.data?.itineraries?.length);

  const FilterWrapperComponent = isMobileOrLargeMobile ? MobileFilterWrapper : Fragment;

  const showBackButton = entryParameters.resultsOnly && !entryParameters.destination.length;
  const hasAffilidError = results.data?.error?.includes('Unknown partner provided.');

  const handleReset = () => {
    resetLocations();
  };

  const popoverActions = isMobile ? <></> : undefined;

  useLayoutEffect(() => {
    loadResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Filters = useCallback(() => {
    return (

      <FiltersWrapper style={{"background": "#ffffff9e","padding":'14px'}}>

        {!!results?.data?.itineraries?.length && (
          <Mobile>
            <SortBy
              topResults={results?.data?.metadata?.topResults || []}
              sortBy={sortBy}
              changeSortBy={changeSortBy}
            />
          </Mobile>
        )}

          <FilterWrapperComponent>
            <TransportFilter
                asButton={isNotDesktop}
                noButtons={!isNotDesktop}
                popoverActions={popoverActions}
            />
            <StopsFilter
                asButton={isNotDesktop}
                noButtons={!isNotDesktop}
                popoverActions={popoverActions}
            />
            <TimesFilter
                asButton={isNotDesktop}
                noButtons={!isNotDesktop}
                popoverActions={popoverActions}
            />
            <PriceFilter
                asButton={isNotDesktop}
                prices={results?.data?.metadata?.prices?.items || []}
                noButtons={!isNotDesktop}
                popoverActions={popoverActions}
            />
            <DaysFilter
                asButton={isNotDesktop}
                noButtons={!isNotDesktop}
                popoverActions={popoverActions}
            />
            <MoreFilter
                asButton={isNotDesktop}
                noButtons={!isNotDesktop}
                popoverActions={popoverActions}
            />
          </FilterWrapperComponent>


      </FiltersWrapper>

    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotDesktop, showFiltersWithResults]);

  return (
    <ResultsContainer spaceBefore={!showFilters}>
      {showFilters && <Filters />}
      {results.loading || !results.data ? (
        <ResultsWrapper>

          <Results>
            {range(5).map(i => (
              <ResultCardPlaceholder key={i} tripCount={1} />
            ))}
          </Results>
        </ResultsWrapper>
      ) : (
        <ResultsWrapper >

          {showFiltersWithResults && (
            <Desktop>
              <SortWrapper style={{"background": "#ffffff9e","padding":'14px'}}>
                <SortBy
                  topResults={results.data.metadata.topResults}
                  sortBy={sortBy}
                  changeSortBy={changeSortBy}
                />
              </SortWrapper>
            </Desktop>
          )}
          {showBackButton && (
            <TextLinkWrapper>
              <TextLink
                loading={results.loading}
                standAlone
                title="Back button"
                onClick={handleReset}
              >
                <ChevronLeftIcon size="small" />
                {translate(('common.back'))}
              </TextLink>
            </TextLinkWrapper>
          )}
          {results?.data?.itineraries?.length ? (
            <Results>

              {results.data.itineraries.map((itinerary, i) => (
                <ResultCard
                  key={itinerary.id}
                  itinerary={mapItinerary(itinerary)}
                  position={i}
                  total={results.data.itineraries.length}
                />
              ))}
              {results.loadingMore && <Loading type="boxLoader" />}
            </Results>
          ) : (
            <NoResults
              handleReset={!entryParameters.destination.length && handleReset}
              invalidAffilid={hasAffilidError}
            />
          )}
        </ResultsWrapper>
      )}
    </ResultsContainer>
  );
};

export default SearchResults;
